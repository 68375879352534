/* eslint-disable */
import {
  Announcement,
  Category,
  Image,
  ProductColorItem,
  ProductMeta,
  SegmentedItem,
  SplitColor,
} from "../common/common";

export const protobufPackage = "store.api.store";

export interface GetHomeRequest {
  /** If given, `Home` will load this waterfall for hits statistics */
  id?:
    | string
    | undefined;
  /** If given, `Home` will load this waterfall for preview */
  previewId?: string | undefined;
}

export interface GetMiniHomeV2Request {
  /**
   * Optional. 大分類英文名稱
   * 如果為空, 各大分類回傳 30 筆商品
   * 如果有值, 該大分類回傳全部商品
   */
  category: string;
}

export interface GetSalesEventRequest {
  /** 模式 */
  mode: GetSalesEventRequest_Mode;
  /** Optional. 廣告參數 */
  adId: string;
  /** Optional. 廣告參數設定流水號 */
  adSeq: number;
}

export enum GetSalesEventRequest_Mode {
  /** MODE_UNSPECIFIED - return all */
  MODE_UNSPECIFIED = 0,
  /** MODE_META - only return `SalesEvent`.`Event` => `id`, `title`, `sub_title`, `end_time` */
  MODE_META = 1,
}

export function getSalesEventRequest_ModeFromJSON(object: any): GetSalesEventRequest_Mode {
  switch (object) {
    case 0:
    case "MODE_UNSPECIFIED":
      return GetSalesEventRequest_Mode.MODE_UNSPECIFIED;
    case 1:
    case "MODE_META":
      return GetSalesEventRequest_Mode.MODE_META;
    default:
      throw new tsProtoGlobalThis.Error("Unrecognized enum value " + object + " for enum GetSalesEventRequest_Mode");
  }
}

export function getSalesEventRequest_ModeToJSON(object: GetSalesEventRequest_Mode): string {
  switch (object) {
    case GetSalesEventRequest_Mode.MODE_UNSPECIFIED:
      return "MODE_UNSPECIFIED";
    case GetSalesEventRequest_Mode.MODE_META:
      return "MODE_META";
    default:
      throw new tsProtoGlobalThis.Error("Unrecognized enum value " + object + " for enum GetSalesEventRequest_Mode");
  }
}

export interface GetSalesEventV2Request {
  /**
   * Optional. 廣告編號
   * 如果為空, 各大分類回傳 24 筆商品
   * 如果有值, 回傳該活動資料及全部商品
   */
  eventId: string;
  /**
   * Optional. 大分類英文名稱
   * 要搭配 event_id 有值的時候才會只撈該分類商品
   */
  eventCategory: string;
  /**
   * Optional. 模式
   * Only valid when `event_id ` and `event_category ` are not given
   */
  mode: GetSalesEventV2Request_Mode;
  /** Optional. 廣告參數 */
  adId: string;
  /** Optional. 廣告參數設定流水號 */
  adSeq: number;
}

export enum GetSalesEventV2Request_Mode {
  /** MODE_UNSPECIFIED - return all */
  MODE_UNSPECIFIED = 0,
  /** MODE_META - only return `SalesEvent`.`Event` => `id`, `title`, `sub_title`, `end_time` */
  MODE_META = 1,
}

export function getSalesEventV2Request_ModeFromJSON(object: any): GetSalesEventV2Request_Mode {
  switch (object) {
    case 0:
    case "MODE_UNSPECIFIED":
      return GetSalesEventV2Request_Mode.MODE_UNSPECIFIED;
    case 1:
    case "MODE_META":
      return GetSalesEventV2Request_Mode.MODE_META;
    default:
      throw new tsProtoGlobalThis.Error("Unrecognized enum value " + object + " for enum GetSalesEventV2Request_Mode");
  }
}

export function getSalesEventV2Request_ModeToJSON(object: GetSalesEventV2Request_Mode): string {
  switch (object) {
    case GetSalesEventV2Request_Mode.MODE_UNSPECIFIED:
      return "MODE_UNSPECIFIED";
    case GetSalesEventV2Request_Mode.MODE_META:
      return "MODE_META";
    default:
      throw new tsProtoGlobalThis.Error("Unrecognized enum value " + object + " for enum GetSalesEventV2Request_Mode");
  }
}

/** Home defines the data in home page */
export interface Home {
  banner: Home_Banners | undefined;
  dynamicBlock: Home_DynamicBlocks | undefined;
  waterfall: Home_Waterfall | undefined;
  searchKeyword: string;
  /** TYPE_TOP announcement. Valid for WEB_MOBILE only. */
  topAnnouncement:
    | Announcement
    | undefined;
  /** TYPE_BANNER announcement. Valid for MOBILE only. */
  announcement: Announcement | undefined;
  specialEffect: Home_SpecialEffect;
}

export enum Home_SpecialEffect {
  NONE = 0,
  SNOW = 1,
  RAIN = 2,
}

export function home_SpecialEffectFromJSON(object: any): Home_SpecialEffect {
  switch (object) {
    case 0:
    case "NONE":
      return Home_SpecialEffect.NONE;
    case 1:
    case "SNOW":
      return Home_SpecialEffect.SNOW;
    case 2:
    case "RAIN":
      return Home_SpecialEffect.RAIN;
    default:
      throw new tsProtoGlobalThis.Error("Unrecognized enum value " + object + " for enum Home_SpecialEffect");
  }
}

export function home_SpecialEffectToJSON(object: Home_SpecialEffect): string {
  switch (object) {
    case Home_SpecialEffect.NONE:
      return "NONE";
    case Home_SpecialEffect.SNOW:
      return "SNOW";
    case Home_SpecialEffect.RAIN:
      return "RAIN";
    default:
      throw new tsProtoGlobalThis.Error("Unrecognized enum value " + object + " for enum Home_SpecialEffect");
  }
}

/** Banners defines the home page banners */
export interface Home_Banners {
  /** aspect ratio of the banner, h / w */
  aspectRatio: number;
  /** banner content */
  banners: Home_Banners_Banner[];
}

/** Banner defines banner item of home page */
export interface Home_Banners_Banner {
  /** link for the banner */
  link: string;
  /** image hash */
  hash: string;
  /** banner id */
  id: string;
}

/** DynamicBlocks defines the home page dynamic blocks section */
export interface Home_DynamicBlocks {
  /** aspect ratio of the dynamic block, h / w */
  aspectRatio: number;
  /** dynamic blocks content */
  dynamicBlocks: Home_DynamicBlocks_DynamicBlock[];
}

/** DynamicBlock defines dynamic block item of home page */
export interface Home_DynamicBlocks_DynamicBlock {
  /** link for the dynamic block */
  link: string;
  /** image hash */
  hash: string;
  /** dynamic block title. Valid for MOBILE only. */
  title: string;
  /** show in dialog */
  showDialog: boolean;
}

export interface Home_Waterfall {
  /** columns for the waterFall. */
  columns: Home_Waterfall_Items[];
}

/** Item defines the item in the waterfall. */
export interface Home_Waterfall_Item {
  /** item id */
  id: string;
  /** segmented item */
  item:
    | SegmentedItem
    | undefined;
  /** item title. Valid for MOBILE only. */
  title: string;
  /** show cart. Valid for MOBILE only. */
  showCart: boolean;
}

/** Items defines the collection of item. should sort by priority. */
export interface Home_Waterfall_Items {
  items: Home_Waterfall_Item[];
}

/** MiniHome defines the data in mini home page */
export interface MiniHome {
  miniHomes: MiniHome_MiniHomeProducts[];
}

export interface MiniHome_MiniHomeProducts {
  /** big category. */
  big:
    | Category
    | undefined;
  /** aspect ratio of the miniHome image, h / w */
  aspectRatio: number;
  /** mini home products */
  content: MiniHome_MiniHomeProducts_MiniHomeProduct[];
  /** newArrival image. Valid for Web_Desktop only. */
  image:
    | Image
    | undefined;
  /** newArrival link. Valid for Web_Desktop only. */
  link: string;
  /**
   * 置頂款號.
   * Only valid when access token is given and Platform is ANDROID or IOS.
   * 包含顧客常購買的款式或系列、加入收藏、加入貨到通知
   */
  topProductIds: string[];
}

export interface MiniHome_MiniHomeProducts_MiniHomeProduct {
  /** product meta */
  meta:
    | ProductMeta
    | undefined;
  /**
   * image hash of 商品列表圖M (width 800px), if absent, using 商品圖 500px.
   * If this is empty, means the product colors need to be split, using split colors instead.
   */
  hash: string;
  /** color information */
  colors: SplitColor[];
  /** 销量 of last seven days */
  sales: number;
  /** 新品顺序 */
  newArrivalPriority: number;
  /** 有庫存 */
  hasInventory: boolean;
  /** 第一個中分類. Valid for WEB_MOBILE only. */
  isFirstMediumCategory: boolean;
}

/** SalesEvent defines the data in sales event page. */
export interface SalesEvent {
  events: SalesEvent_Event[];
}

/** Event defines an promotion event of products. */
export interface SalesEvent_Event {
  /** event id */
  id: string;
  /** event title */
  title: string;
  /** event sub title */
  subTitle: string;
  /** epoch time in milliseconds */
  endTime: number;
  /** event categories */
  categories: SalesEvent_Event_EventCategory[];
}

/** EventCategory defines the category and content for a promotion. */
export interface SalesEvent_Event_EventCategory {
  big:
    | Category
    | undefined;
  /** event content */
  content: ProductColorItem | undefined;
}

function createBaseGetHomeRequest(): GetHomeRequest {
  return { id: undefined, previewId: undefined };
}

export const GetHomeRequest = {
  fromJSON(object: any): GetHomeRequest {
    return {
      id: isSet(object.id) ? String(object.id) : undefined,
      previewId: isSet(object.previewId) ? String(object.previewId) : undefined,
    };
  },

  toJSON(message: GetHomeRequest): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.previewId !== undefined && (obj.previewId = message.previewId);
    return obj;
  },

  create<I extends Exact<DeepPartial<GetHomeRequest>, I>>(base?: I): GetHomeRequest {
    return GetHomeRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetHomeRequest>, I>>(object: I): GetHomeRequest {
    const message = createBaseGetHomeRequest();
    message.id = object.id ?? undefined;
    message.previewId = object.previewId ?? undefined;
    return message;
  },
};

function createBaseGetMiniHomeV2Request(): GetMiniHomeV2Request {
  return { category: "" };
}

export const GetMiniHomeV2Request = {
  fromJSON(object: any): GetMiniHomeV2Request {
    return { category: isSet(object.category) ? String(object.category) : "" };
  },

  toJSON(message: GetMiniHomeV2Request): unknown {
    const obj: any = {};
    message.category !== undefined && (obj.category = message.category);
    return obj;
  },

  create<I extends Exact<DeepPartial<GetMiniHomeV2Request>, I>>(base?: I): GetMiniHomeV2Request {
    return GetMiniHomeV2Request.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetMiniHomeV2Request>, I>>(object: I): GetMiniHomeV2Request {
    const message = createBaseGetMiniHomeV2Request();
    message.category = object.category ?? "";
    return message;
  },
};

function createBaseGetSalesEventRequest(): GetSalesEventRequest {
  return { mode: 0, adId: "", adSeq: 0 };
}

export const GetSalesEventRequest = {
  fromJSON(object: any): GetSalesEventRequest {
    return {
      mode: isSet(object.mode) ? getSalesEventRequest_ModeFromJSON(object.mode) : 0,
      adId: isSet(object.adId) ? String(object.adId) : "",
      adSeq: isSet(object.adSeq) ? Number(object.adSeq) : 0,
    };
  },

  toJSON(message: GetSalesEventRequest): unknown {
    const obj: any = {};
    message.mode !== undefined && (obj.mode = getSalesEventRequest_ModeToJSON(message.mode));
    message.adId !== undefined && (obj.adId = message.adId);
    message.adSeq !== undefined && (obj.adSeq = Math.round(message.adSeq));
    return obj;
  },

  create<I extends Exact<DeepPartial<GetSalesEventRequest>, I>>(base?: I): GetSalesEventRequest {
    return GetSalesEventRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetSalesEventRequest>, I>>(object: I): GetSalesEventRequest {
    const message = createBaseGetSalesEventRequest();
    message.mode = object.mode ?? 0;
    message.adId = object.adId ?? "";
    message.adSeq = object.adSeq ?? 0;
    return message;
  },
};

function createBaseGetSalesEventV2Request(): GetSalesEventV2Request {
  return { eventId: "", eventCategory: "", mode: 0, adId: "", adSeq: 0 };
}

export const GetSalesEventV2Request = {
  fromJSON(object: any): GetSalesEventV2Request {
    return {
      eventId: isSet(object.eventId) ? String(object.eventId) : "",
      eventCategory: isSet(object.eventCategory) ? String(object.eventCategory) : "",
      mode: isSet(object.mode) ? getSalesEventV2Request_ModeFromJSON(object.mode) : 0,
      adId: isSet(object.adId) ? String(object.adId) : "",
      adSeq: isSet(object.adSeq) ? Number(object.adSeq) : 0,
    };
  },

  toJSON(message: GetSalesEventV2Request): unknown {
    const obj: any = {};
    message.eventId !== undefined && (obj.eventId = message.eventId);
    message.eventCategory !== undefined && (obj.eventCategory = message.eventCategory);
    message.mode !== undefined && (obj.mode = getSalesEventV2Request_ModeToJSON(message.mode));
    message.adId !== undefined && (obj.adId = message.adId);
    message.adSeq !== undefined && (obj.adSeq = Math.round(message.adSeq));
    return obj;
  },

  create<I extends Exact<DeepPartial<GetSalesEventV2Request>, I>>(base?: I): GetSalesEventV2Request {
    return GetSalesEventV2Request.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetSalesEventV2Request>, I>>(object: I): GetSalesEventV2Request {
    const message = createBaseGetSalesEventV2Request();
    message.eventId = object.eventId ?? "";
    message.eventCategory = object.eventCategory ?? "";
    message.mode = object.mode ?? 0;
    message.adId = object.adId ?? "";
    message.adSeq = object.adSeq ?? 0;
    return message;
  },
};

function createBaseHome(): Home {
  return {
    banner: undefined,
    dynamicBlock: undefined,
    waterfall: undefined,
    searchKeyword: "",
    topAnnouncement: undefined,
    announcement: undefined,
    specialEffect: 0,
  };
}

export const Home = {
  fromJSON(object: any): Home {
    return {
      banner: isSet(object.banner) ? Home_Banners.fromJSON(object.banner) : undefined,
      dynamicBlock: isSet(object.dynamicBlock) ? Home_DynamicBlocks.fromJSON(object.dynamicBlock) : undefined,
      waterfall: isSet(object.waterfall) ? Home_Waterfall.fromJSON(object.waterfall) : undefined,
      searchKeyword: isSet(object.searchKeyword) ? String(object.searchKeyword) : "",
      topAnnouncement: isSet(object.topAnnouncement) ? Announcement.fromJSON(object.topAnnouncement) : undefined,
      announcement: isSet(object.announcement) ? Announcement.fromJSON(object.announcement) : undefined,
      specialEffect: isSet(object.specialEffect) ? home_SpecialEffectFromJSON(object.specialEffect) : 0,
    };
  },

  toJSON(message: Home): unknown {
    const obj: any = {};
    message.banner !== undefined && (obj.banner = message.banner ? Home_Banners.toJSON(message.banner) : undefined);
    message.dynamicBlock !== undefined &&
      (obj.dynamicBlock = message.dynamicBlock ? Home_DynamicBlocks.toJSON(message.dynamicBlock) : undefined);
    message.waterfall !== undefined &&
      (obj.waterfall = message.waterfall ? Home_Waterfall.toJSON(message.waterfall) : undefined);
    message.searchKeyword !== undefined && (obj.searchKeyword = message.searchKeyword);
    message.topAnnouncement !== undefined &&
      (obj.topAnnouncement = message.topAnnouncement ? Announcement.toJSON(message.topAnnouncement) : undefined);
    message.announcement !== undefined &&
      (obj.announcement = message.announcement ? Announcement.toJSON(message.announcement) : undefined);
    message.specialEffect !== undefined && (obj.specialEffect = home_SpecialEffectToJSON(message.specialEffect));
    return obj;
  },

  create<I extends Exact<DeepPartial<Home>, I>>(base?: I): Home {
    return Home.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Home>, I>>(object: I): Home {
    const message = createBaseHome();
    message.banner = (object.banner !== undefined && object.banner !== null)
      ? Home_Banners.fromPartial(object.banner)
      : undefined;
    message.dynamicBlock = (object.dynamicBlock !== undefined && object.dynamicBlock !== null)
      ? Home_DynamicBlocks.fromPartial(object.dynamicBlock)
      : undefined;
    message.waterfall = (object.waterfall !== undefined && object.waterfall !== null)
      ? Home_Waterfall.fromPartial(object.waterfall)
      : undefined;
    message.searchKeyword = object.searchKeyword ?? "";
    message.topAnnouncement = (object.topAnnouncement !== undefined && object.topAnnouncement !== null)
      ? Announcement.fromPartial(object.topAnnouncement)
      : undefined;
    message.announcement = (object.announcement !== undefined && object.announcement !== null)
      ? Announcement.fromPartial(object.announcement)
      : undefined;
    message.specialEffect = object.specialEffect ?? 0;
    return message;
  },
};

function createBaseHome_Banners(): Home_Banners {
  return { aspectRatio: 0, banners: [] };
}

export const Home_Banners = {
  fromJSON(object: any): Home_Banners {
    return {
      aspectRatio: isSet(object.aspectRatio) ? Number(object.aspectRatio) : 0,
      banners: Array.isArray(object?.banners) ? object.banners.map((e: any) => Home_Banners_Banner.fromJSON(e)) : [],
    };
  },

  toJSON(message: Home_Banners): unknown {
    const obj: any = {};
    message.aspectRatio !== undefined && (obj.aspectRatio = message.aspectRatio);
    if (message.banners) {
      obj.banners = message.banners.map((e) => e ? Home_Banners_Banner.toJSON(e) : undefined);
    } else {
      obj.banners = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Home_Banners>, I>>(base?: I): Home_Banners {
    return Home_Banners.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Home_Banners>, I>>(object: I): Home_Banners {
    const message = createBaseHome_Banners();
    message.aspectRatio = object.aspectRatio ?? 0;
    message.banners = object.banners?.map((e) => Home_Banners_Banner.fromPartial(e)) || [];
    return message;
  },
};

function createBaseHome_Banners_Banner(): Home_Banners_Banner {
  return { link: "", hash: "", id: "" };
}

export const Home_Banners_Banner = {
  fromJSON(object: any): Home_Banners_Banner {
    return {
      link: isSet(object.link) ? String(object.link) : "",
      hash: isSet(object.hash) ? String(object.hash) : "",
      id: isSet(object.id) ? String(object.id) : "",
    };
  },

  toJSON(message: Home_Banners_Banner): unknown {
    const obj: any = {};
    message.link !== undefined && (obj.link = message.link);
    message.hash !== undefined && (obj.hash = message.hash);
    message.id !== undefined && (obj.id = message.id);
    return obj;
  },

  create<I extends Exact<DeepPartial<Home_Banners_Banner>, I>>(base?: I): Home_Banners_Banner {
    return Home_Banners_Banner.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Home_Banners_Banner>, I>>(object: I): Home_Banners_Banner {
    const message = createBaseHome_Banners_Banner();
    message.link = object.link ?? "";
    message.hash = object.hash ?? "";
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseHome_DynamicBlocks(): Home_DynamicBlocks {
  return { aspectRatio: 0, dynamicBlocks: [] };
}

export const Home_DynamicBlocks = {
  fromJSON(object: any): Home_DynamicBlocks {
    return {
      aspectRatio: isSet(object.aspectRatio) ? Number(object.aspectRatio) : 0,
      dynamicBlocks: Array.isArray(object?.dynamicBlocks)
        ? object.dynamicBlocks.map((e: any) => Home_DynamicBlocks_DynamicBlock.fromJSON(e))
        : [],
    };
  },

  toJSON(message: Home_DynamicBlocks): unknown {
    const obj: any = {};
    message.aspectRatio !== undefined && (obj.aspectRatio = message.aspectRatio);
    if (message.dynamicBlocks) {
      obj.dynamicBlocks = message.dynamicBlocks.map((e) => e ? Home_DynamicBlocks_DynamicBlock.toJSON(e) : undefined);
    } else {
      obj.dynamicBlocks = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Home_DynamicBlocks>, I>>(base?: I): Home_DynamicBlocks {
    return Home_DynamicBlocks.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Home_DynamicBlocks>, I>>(object: I): Home_DynamicBlocks {
    const message = createBaseHome_DynamicBlocks();
    message.aspectRatio = object.aspectRatio ?? 0;
    message.dynamicBlocks = object.dynamicBlocks?.map((e) => Home_DynamicBlocks_DynamicBlock.fromPartial(e)) || [];
    return message;
  },
};

function createBaseHome_DynamicBlocks_DynamicBlock(): Home_DynamicBlocks_DynamicBlock {
  return { link: "", hash: "", title: "", showDialog: false };
}

export const Home_DynamicBlocks_DynamicBlock = {
  fromJSON(object: any): Home_DynamicBlocks_DynamicBlock {
    return {
      link: isSet(object.link) ? String(object.link) : "",
      hash: isSet(object.hash) ? String(object.hash) : "",
      title: isSet(object.title) ? String(object.title) : "",
      showDialog: isSet(object.showDialog) ? Boolean(object.showDialog) : false,
    };
  },

  toJSON(message: Home_DynamicBlocks_DynamicBlock): unknown {
    const obj: any = {};
    message.link !== undefined && (obj.link = message.link);
    message.hash !== undefined && (obj.hash = message.hash);
    message.title !== undefined && (obj.title = message.title);
    message.showDialog !== undefined && (obj.showDialog = message.showDialog);
    return obj;
  },

  create<I extends Exact<DeepPartial<Home_DynamicBlocks_DynamicBlock>, I>>(base?: I): Home_DynamicBlocks_DynamicBlock {
    return Home_DynamicBlocks_DynamicBlock.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Home_DynamicBlocks_DynamicBlock>, I>>(
    object: I,
  ): Home_DynamicBlocks_DynamicBlock {
    const message = createBaseHome_DynamicBlocks_DynamicBlock();
    message.link = object.link ?? "";
    message.hash = object.hash ?? "";
    message.title = object.title ?? "";
    message.showDialog = object.showDialog ?? false;
    return message;
  },
};

function createBaseHome_Waterfall(): Home_Waterfall {
  return { columns: [] };
}

export const Home_Waterfall = {
  fromJSON(object: any): Home_Waterfall {
    return {
      columns: Array.isArray(object?.columns) ? object.columns.map((e: any) => Home_Waterfall_Items.fromJSON(e)) : [],
    };
  },

  toJSON(message: Home_Waterfall): unknown {
    const obj: any = {};
    if (message.columns) {
      obj.columns = message.columns.map((e) => e ? Home_Waterfall_Items.toJSON(e) : undefined);
    } else {
      obj.columns = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Home_Waterfall>, I>>(base?: I): Home_Waterfall {
    return Home_Waterfall.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Home_Waterfall>, I>>(object: I): Home_Waterfall {
    const message = createBaseHome_Waterfall();
    message.columns = object.columns?.map((e) => Home_Waterfall_Items.fromPartial(e)) || [];
    return message;
  },
};

function createBaseHome_Waterfall_Item(): Home_Waterfall_Item {
  return { id: "", item: undefined, title: "", showCart: false };
}

export const Home_Waterfall_Item = {
  fromJSON(object: any): Home_Waterfall_Item {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      item: isSet(object.item) ? SegmentedItem.fromJSON(object.item) : undefined,
      title: isSet(object.title) ? String(object.title) : "",
      showCart: isSet(object.showCart) ? Boolean(object.showCart) : false,
    };
  },

  toJSON(message: Home_Waterfall_Item): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.item !== undefined && (obj.item = message.item ? SegmentedItem.toJSON(message.item) : undefined);
    message.title !== undefined && (obj.title = message.title);
    message.showCart !== undefined && (obj.showCart = message.showCart);
    return obj;
  },

  create<I extends Exact<DeepPartial<Home_Waterfall_Item>, I>>(base?: I): Home_Waterfall_Item {
    return Home_Waterfall_Item.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Home_Waterfall_Item>, I>>(object: I): Home_Waterfall_Item {
    const message = createBaseHome_Waterfall_Item();
    message.id = object.id ?? "";
    message.item = (object.item !== undefined && object.item !== null)
      ? SegmentedItem.fromPartial(object.item)
      : undefined;
    message.title = object.title ?? "";
    message.showCart = object.showCart ?? false;
    return message;
  },
};

function createBaseHome_Waterfall_Items(): Home_Waterfall_Items {
  return { items: [] };
}

export const Home_Waterfall_Items = {
  fromJSON(object: any): Home_Waterfall_Items {
    return { items: Array.isArray(object?.items) ? object.items.map((e: any) => Home_Waterfall_Item.fromJSON(e)) : [] };
  },

  toJSON(message: Home_Waterfall_Items): unknown {
    const obj: any = {};
    if (message.items) {
      obj.items = message.items.map((e) => e ? Home_Waterfall_Item.toJSON(e) : undefined);
    } else {
      obj.items = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Home_Waterfall_Items>, I>>(base?: I): Home_Waterfall_Items {
    return Home_Waterfall_Items.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Home_Waterfall_Items>, I>>(object: I): Home_Waterfall_Items {
    const message = createBaseHome_Waterfall_Items();
    message.items = object.items?.map((e) => Home_Waterfall_Item.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMiniHome(): MiniHome {
  return { miniHomes: [] };
}

export const MiniHome = {
  fromJSON(object: any): MiniHome {
    return {
      miniHomes: Array.isArray(object?.miniHomes)
        ? object.miniHomes.map((e: any) => MiniHome_MiniHomeProducts.fromJSON(e))
        : [],
    };
  },

  toJSON(message: MiniHome): unknown {
    const obj: any = {};
    if (message.miniHomes) {
      obj.miniHomes = message.miniHomes.map((e) => e ? MiniHome_MiniHomeProducts.toJSON(e) : undefined);
    } else {
      obj.miniHomes = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MiniHome>, I>>(base?: I): MiniHome {
    return MiniHome.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MiniHome>, I>>(object: I): MiniHome {
    const message = createBaseMiniHome();
    message.miniHomes = object.miniHomes?.map((e) => MiniHome_MiniHomeProducts.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMiniHome_MiniHomeProducts(): MiniHome_MiniHomeProducts {
  return { big: undefined, aspectRatio: 0, content: [], image: undefined, link: "", topProductIds: [] };
}

export const MiniHome_MiniHomeProducts = {
  fromJSON(object: any): MiniHome_MiniHomeProducts {
    return {
      big: isSet(object.big) ? Category.fromJSON(object.big) : undefined,
      aspectRatio: isSet(object.aspectRatio) ? Number(object.aspectRatio) : 0,
      content: Array.isArray(object?.content)
        ? object.content.map((e: any) => MiniHome_MiniHomeProducts_MiniHomeProduct.fromJSON(e))
        : [],
      image: isSet(object.image) ? Image.fromJSON(object.image) : undefined,
      link: isSet(object.link) ? String(object.link) : "",
      topProductIds: Array.isArray(object?.topProductIds) ? object.topProductIds.map((e: any) => String(e)) : [],
    };
  },

  toJSON(message: MiniHome_MiniHomeProducts): unknown {
    const obj: any = {};
    message.big !== undefined && (obj.big = message.big ? Category.toJSON(message.big) : undefined);
    message.aspectRatio !== undefined && (obj.aspectRatio = message.aspectRatio);
    if (message.content) {
      obj.content = message.content.map((e) => e ? MiniHome_MiniHomeProducts_MiniHomeProduct.toJSON(e) : undefined);
    } else {
      obj.content = [];
    }
    message.image !== undefined && (obj.image = message.image ? Image.toJSON(message.image) : undefined);
    message.link !== undefined && (obj.link = message.link);
    if (message.topProductIds) {
      obj.topProductIds = message.topProductIds.map((e) => e);
    } else {
      obj.topProductIds = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<MiniHome_MiniHomeProducts>, I>>(base?: I): MiniHome_MiniHomeProducts {
    return MiniHome_MiniHomeProducts.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MiniHome_MiniHomeProducts>, I>>(object: I): MiniHome_MiniHomeProducts {
    const message = createBaseMiniHome_MiniHomeProducts();
    message.big = (object.big !== undefined && object.big !== null) ? Category.fromPartial(object.big) : undefined;
    message.aspectRatio = object.aspectRatio ?? 0;
    message.content = object.content?.map((e) => MiniHome_MiniHomeProducts_MiniHomeProduct.fromPartial(e)) || [];
    message.image = (object.image !== undefined && object.image !== null) ? Image.fromPartial(object.image) : undefined;
    message.link = object.link ?? "";
    message.topProductIds = object.topProductIds?.map((e) => e) || [];
    return message;
  },
};

function createBaseMiniHome_MiniHomeProducts_MiniHomeProduct(): MiniHome_MiniHomeProducts_MiniHomeProduct {
  return {
    meta: undefined,
    hash: "",
    colors: [],
    sales: 0,
    newArrivalPriority: 0,
    hasInventory: false,
    isFirstMediumCategory: false,
  };
}

export const MiniHome_MiniHomeProducts_MiniHomeProduct = {
  fromJSON(object: any): MiniHome_MiniHomeProducts_MiniHomeProduct {
    return {
      meta: isSet(object.meta) ? ProductMeta.fromJSON(object.meta) : undefined,
      hash: isSet(object.hash) ? String(object.hash) : "",
      colors: Array.isArray(object?.colors)
        ? object.colors.map((e: any) => SplitColor.fromJSON(e))
        : [],
      sales: isSet(object.sales) ? Number(object.sales) : 0,
      newArrivalPriority: isSet(object.newArrivalPriority) ? Number(object.newArrivalPriority) : 0,
      hasInventory: isSet(object.hasInventory) ? Boolean(object.hasInventory) : false,
      isFirstMediumCategory: isSet(object.isFirstMediumCategory) ? Boolean(object.isFirstMediumCategory) : false,
    };
  },

  toJSON(message: MiniHome_MiniHomeProducts_MiniHomeProduct): unknown {
    const obj: any = {};
    message.meta !== undefined && (obj.meta = message.meta ? ProductMeta.toJSON(message.meta) : undefined);
    message.hash !== undefined && (obj.hash = message.hash);
    if (message.colors) {
      obj.colors = message.colors.map((e) => e ? SplitColor.toJSON(e) : undefined);
    } else {
      obj.colors = [];
    }
    message.sales !== undefined && (obj.sales = Math.round(message.sales));
    message.newArrivalPriority !== undefined && (obj.newArrivalPriority = Math.round(message.newArrivalPriority));
    message.hasInventory !== undefined && (obj.hasInventory = message.hasInventory);
    message.isFirstMediumCategory !== undefined && (obj.isFirstMediumCategory = message.isFirstMediumCategory);
    return obj;
  },

  create<I extends Exact<DeepPartial<MiniHome_MiniHomeProducts_MiniHomeProduct>, I>>(
    base?: I,
  ): MiniHome_MiniHomeProducts_MiniHomeProduct {
    return MiniHome_MiniHomeProducts_MiniHomeProduct.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MiniHome_MiniHomeProducts_MiniHomeProduct>, I>>(
    object: I,
  ): MiniHome_MiniHomeProducts_MiniHomeProduct {
    const message = createBaseMiniHome_MiniHomeProducts_MiniHomeProduct();
    message.meta = (object.meta !== undefined && object.meta !== null)
      ? ProductMeta.fromPartial(object.meta)
      : undefined;
    message.hash = object.hash ?? "";
    message.colors = object.colors?.map((e) => SplitColor.fromPartial(e)) || [];
    message.sales = object.sales ?? 0;
    message.newArrivalPriority = object.newArrivalPriority ?? 0;
    message.hasInventory = object.hasInventory ?? false;
    message.isFirstMediumCategory = object.isFirstMediumCategory ?? false;
    return message;
  },
};

function createBaseSalesEvent(): SalesEvent {
  return { events: [] };
}

export const SalesEvent = {
  fromJSON(object: any): SalesEvent {
    return { events: Array.isArray(object?.events) ? object.events.map((e: any) => SalesEvent_Event.fromJSON(e)) : [] };
  },

  toJSON(message: SalesEvent): unknown {
    const obj: any = {};
    if (message.events) {
      obj.events = message.events.map((e) => e ? SalesEvent_Event.toJSON(e) : undefined);
    } else {
      obj.events = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SalesEvent>, I>>(base?: I): SalesEvent {
    return SalesEvent.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SalesEvent>, I>>(object: I): SalesEvent {
    const message = createBaseSalesEvent();
    message.events = object.events?.map((e) => SalesEvent_Event.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSalesEvent_Event(): SalesEvent_Event {
  return { id: "", title: "", subTitle: "", endTime: 0, categories: [] };
}

export const SalesEvent_Event = {
  fromJSON(object: any): SalesEvent_Event {
    return {
      id: isSet(object.id) ? String(object.id) : "",
      title: isSet(object.title) ? String(object.title) : "",
      subTitle: isSet(object.subTitle) ? String(object.subTitle) : "",
      endTime: isSet(object.endTime) ? Number(object.endTime) : 0,
      categories: Array.isArray(object?.categories)
        ? object.categories.map((e: any) => SalesEvent_Event_EventCategory.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SalesEvent_Event): unknown {
    const obj: any = {};
    message.id !== undefined && (obj.id = message.id);
    message.title !== undefined && (obj.title = message.title);
    message.subTitle !== undefined && (obj.subTitle = message.subTitle);
    message.endTime !== undefined && (obj.endTime = Math.round(message.endTime));
    if (message.categories) {
      obj.categories = message.categories.map((e) => e ? SalesEvent_Event_EventCategory.toJSON(e) : undefined);
    } else {
      obj.categories = [];
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SalesEvent_Event>, I>>(base?: I): SalesEvent_Event {
    return SalesEvent_Event.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SalesEvent_Event>, I>>(object: I): SalesEvent_Event {
    const message = createBaseSalesEvent_Event();
    message.id = object.id ?? "";
    message.title = object.title ?? "";
    message.subTitle = object.subTitle ?? "";
    message.endTime = object.endTime ?? 0;
    message.categories = object.categories?.map((e) => SalesEvent_Event_EventCategory.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSalesEvent_Event_EventCategory(): SalesEvent_Event_EventCategory {
  return { big: undefined, content: undefined };
}

export const SalesEvent_Event_EventCategory = {
  fromJSON(object: any): SalesEvent_Event_EventCategory {
    return {
      big: isSet(object.big) ? Category.fromJSON(object.big) : undefined,
      content: isSet(object.content) ? ProductColorItem.fromJSON(object.content) : undefined,
    };
  },

  toJSON(message: SalesEvent_Event_EventCategory): unknown {
    const obj: any = {};
    message.big !== undefined && (obj.big = message.big ? Category.toJSON(message.big) : undefined);
    message.content !== undefined &&
      (obj.content = message.content ? ProductColorItem.toJSON(message.content) : undefined);
    return obj;
  },

  create<I extends Exact<DeepPartial<SalesEvent_Event_EventCategory>, I>>(base?: I): SalesEvent_Event_EventCategory {
    return SalesEvent_Event_EventCategory.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SalesEvent_Event_EventCategory>, I>>(
    object: I,
  ): SalesEvent_Event_EventCategory {
    const message = createBaseSalesEvent_Event_EventCategory();
    message.big = (object.big !== undefined && object.big !== null) ? Category.fromPartial(object.big) : undefined;
    message.content = (object.content !== undefined && object.content !== null)
      ? ProductColorItem.fromPartial(object.content)
      : undefined;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
